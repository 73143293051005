'use client';

import React, { useEffect, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import '../slider/product-slide.css';

import { Pagination, Autoplay } from 'swiper/modules';
import Image from 'next/image';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@/store/store';
import { getBannerData } from '@/store/slices/actions/home-action';
import {
    setDiscountFilter,
    setHeaderFilter,
    setIsDiscountFilter,
} from '@/store/slices/filter-slice';
import { useRouter } from 'next/navigation';
import { SetThirdCategorySlugs } from '@/store/slices/categorySlice';
import { SetBrandAttributeSlugs } from '@/store/slices/brand-slice';

export default function MainBanner() {
    const dispatch = useDispatch<AppDispatch>();
    const router = useRouter();
    const mainBanners = useSelector(
        (state: any) => state.home_store.mainBanners
    );

    const pagination = {
        clickable: true,
    };

    const [isMobile, setIsMobile] = useState<undefined | boolean>(undefined);
    useEffect(() => {
        setIsMobile(window.innerWidth <= 768);
        dispatch(getBannerData({ pageSize: 10, page: 1 }));
    }, []);

    if (typeof isMobile !== 'boolean') return null;

    const filteredCrazyDealsData = mainBanners.filter(
        (item: any) => item.status
    );

    return (
        <>
            <Swiper
                spaceBetween={20}
                pagination={pagination}
                modules={[Pagination, Autoplay]}
                className="mySwiper2 pb-9"
                slidesPerView={1}
                autoplay={{ delay: 5000, disableOnInteraction: false }}
            >
                {filteredCrazyDealsData?.map((item: any, index: any) => (
                    <SwiperSlide key={index}>
                        {/* h-[60vh] @lg:h-[100%] border-2 @sm:h-[30vh] @md:h-[21vh] mb-10 @sm:mb-28 */}
                        <div
                            className="w-full  @sm:mb-8"
                            onClick={(e) => {
                                dispatch(setHeaderFilter(true));
                                let arr = [
                                    item?.minDiscount
                                        ? Number(item?.minDiscount)
                                        : null,
                                    item?.maxDiscount
                                        ? Number(item?.maxDiscount)
                                        : null,
                                ];
                                dispatch(setDiscountFilter(arr));
                                dispatch(setIsDiscountFilter(true));
                                if (item.categoryId) {
                                    dispatch(
                                        SetThirdCategorySlugs([
                                            item?.categoryId?.slug,
                                        ])
                                    );
                                }

                                dispatch(
                                    SetBrandAttributeSlugs([
                                        item?.brandId?.slug,
                                    ])
                                );
                                const isDiscountPresent = arr.every(
                                    (value) => value !== null
                                );

                                var baseUrl = '';

                                if (item.brandId && item?.minDiscount) {
                                    baseUrl = `/product-listing?page=1&brand=${item?.brandId?.slug}`;
                                    let url = isDiscountPresent
                                        ? `${baseUrl}&discount=${arr?.toString()}`
                                        : baseUrl;
                                    router.push(url);
                                }

                                if (
                                    item.brandId &&
                                    item.minDiscount == null &&
                                    item.maxDiscount == null
                                ) {
                                    baseUrl = `/product-listing?page=1&brand=${item?.brandId?.slug}`;
                                    router.push(baseUrl);
                                }
                                if (
                                    item.categoryId &&
                                    item.minDiscount == null &&
                                    item.maxDiscount == null
                                ) {
                                    baseUrl = `/product-listing?page=1&third=${item?.categoryId?.slug}`;
                                    router.push(baseUrl);
                                }
                                if (item.categoryId && item?.minDiscount) {
                                    baseUrl = `/product-listing?page=1&third=${item?.categoryId?.slug}`;
                                    let url = isDiscountPresent
                                        ? `${baseUrl}&discount=${arr?.toString()}`
                                        : baseUrl;
                                    router.push(url);
                                }
                            }}
                        >
                            <div className="text-black @sm:aspect-square aspect-[6/2.2] rounded-lg ">
                                {/* <Image className="w-full h-full object-contain" width={800} height={800} alt="banner" src={isMobile ? slide.bannerImgMobile : slide.bannerImgDesktop}
                 /> */}
                                <Image
                                    className=" cursor-pointer w-full rounded-lg"
                                    layout="fill"
                                    // width={1920}
                                    // height={800}
                                    quality={100}
                                    alt="banner"
                                    src={
                                        isMobile
                                            ? item?.mobileBannerImg
                                            : item?.desktopBannerImg
                                    }
                                />
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
}
