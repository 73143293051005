'use client';

import React, { useEffect, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';

import { Autoplay, Navigation } from 'swiper/modules';
import Image from 'next/image';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@/store/store';
import { getTopBrandsData } from '@/store/slices/actions/home-action';
import { useRouter } from 'next/navigation';
import { SetBrandAttributeSlugs } from '@/store/slices/brand-slice';
import { ClearCategoryId } from '@/store/slices/categorySlice';
import {
    setFirstBrandSlug,
    setHeaderFilter,
} from '@/store/slices/filter-slice';

const topBrand = [
    {
        id: 1,
        path: '/images/home/FeaturedCategories/brands/Pampers-Logo.svg',
        width: 100,
        height: 100,
        brand: 'string',
    },
    {
        id: 2,
        path: '/images/home/FeaturedCategories/brands/Loreal-logo.svg',
        width: 100,
        height: 100,
        brand: 'string',
    },
    {
        id: 3,
        path: '/images/home/FeaturedCategories/brands/Nivea.svg',
        width: 100,
        height: 100,
        brand: 'string',
    },
    {
        id: 4,
        path: '/images/home/FeaturedCategories/brands/Oral-b.svg',
        width: 100,
        height: 100,
        brand: 'string',
    },
    {
        id: 5,
        path: '/images/home/FeaturedCategories/brands/Loreal-logo.svg',
        width: 100,
        height: 100,
        brand: 'string',
    },
    {
        id: 6,
        path: '/images/home/FeaturedCategories/brands/Oral-b.svg',
        width: 100,
        height: 100,
        brand: 'string',
    },
    {
        id: 7,
        path: '/images/home/FeaturedCategories/brands/cetaphil-logo.svg',
        width: 100,
        height: 100,
        brand: 'string',
    },
    {
        id: 8,
        path: '/images/home/FeaturedCategories/brands/Pampers-Logo.svg',
        width: 100,
        height: 100,
        brand: 'string',
    },
];

const CommonSlider: React.FC = ({}) => {
    const dispatch = useDispatch<AppDispatch>();
    const router = useRouter();
    const topBrands = useSelector(
        (state: any) => state.home_store.topBrandData
    );
    const [isMobile, setIsMobile] = useState<undefined | boolean>(undefined);

    const handleItemClick = (brandSlug: string) => {
        dispatch(setHeaderFilter(true));
        dispatch(SetBrandAttributeSlugs([brandSlug]));
        dispatch(setFirstBrandSlug(brandSlug));
        router.push(`/product-listing?page=1&brand=${brandSlug}`);
    };

    useEffect(() => {
        setIsMobile(window.innerWidth <= 768);
        dispatch(ClearCategoryId());
        dispatch(getTopBrandsData());
    }, []);

    if (typeof isMobile !== 'boolean') return null;
    return (
        <div className="rounded-md">
            <Swiper
                slidesPerView={2}
                spaceBetween={30}
                // loop={true}
                rewind={true}
                pagination={{
                    clickable: true,
                }}
                // navigation={true}
                navigation={!isMobile}
                // modules={[Navigation]}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                modules={!isMobile ? [Navigation, Autoplay] : undefined}
                className="mySwiper2 px-[0%]"
                breakpoints={{
                    640: {
                        slidesPerView: 5,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                    },
                    1024: {
                        slidesPerView: 5,
                        spaceBetween: 0,
                    },
                }}
            >
                {topBrands?.map((slide: any, index: any) => (
                    <SwiperSlide
                        className="rounded-lg @sm:mb-5 px-[3.5%] @sm:px-[3%] @md:px-[0%] @md:mx-[4%]"
                        key={index}
                    >
                        <Image
                            src={slide.image ? slide.image : '/logo'}
                            onClick={(e) => handleItemClick(slide?.slug)}
                            className="object-contain w-full h-full aspect-[4/3]  @sm:aspect-[2/1] cursor-pointer "
                            width={1920}
                            height={1080}
                            alt="Alt Image Text"
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default CommonSlider;
