'use client';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store'; // Import the type of your root state
import MainBanner from './main-banners';
import FeaturedCategories from './featured-categories';
import Link from 'next/link';
import CommonSlider from '../slider/slider';
import Image from 'next/image';
import FeaturedProductSlider from './featured-product-slider';
import BestSelling from './best-selling';
import LuxuryProducts from './luxury-products';
import BlogCard from './blog-card';
import ConsciousWall from './conscious-wall';
import CrazyDeals from './crazy-deals';
import TopBrand from './top-brands';
import ChooseExcellence from './choose-excellence';
import FooterBanner from './footer-banner';
import GetInTouch from './get-in-touch';
import {
    ManipualteWishlistData,
    getBestSellingProduct,
    getConsiousWallProduct,
    getFeaturedProduct,
    getInroducingProduct,
} from '@/store/slices/actions/home-action';
import { useRouter } from 'next/navigation';
import userService from '@/services/userService';
import { SetUserId } from '@/store/slices/wishlist-slice';
import IPAddress from './ip-address';
import DeviceType from './device-type';
import { getCookie } from 'cookies-next';
import { Skeleton } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { getWistlistData } from '@/store/slices/actions/wishlist-action';

import CardList from './card-list';

// import start for bestselling product
import calvinKlein from '../../../public/images/home/bestselling-products/calvin-klein.png';
import kama from '../../../public/images/home/bestselling-products/kama.png';
import lorealParis from '../../../public/images/home/bestselling-products/loreal-paris.jpg';
import maybeline from '../../../public/images/home/bestselling-products/maybeline.jpg';
import superDefence from '../../../public/images/home/bestselling-products/super-defence.png';
// import end for bestselling product

// import start for featured product data
import anastasia from '../../../public/images/home/FeaturedProduct/anastasia.jpg';
import cerave from '../../../public/images/home/FeaturedProduct/cerave.jpg';
import ferregamo from '../../../public/images/home/FeaturedProduct/ferregamo.png';
import vitaminsAndNutrition from '../../../public/images/home/FeaturedProduct/vitamins-and-nutrition.png';
import cosrx from '../../../public/images/home/FeaturedProduct/cosrx.jpg';
// import end for featured product data

// import start for bestselling categories
import blush from '../../../public/images/home/bestselling-categories/Blush.jpg';
import hairCare from '../../../public/images/home/bestselling-categories/Hair Care.png';
import moisturizer from '../../../public/images/home/bestselling-categories/Moisturizer.jpg';
import womenPerfume from '../../../public/images/home/bestselling-categories/Women Perfume.png';
// import end for bestselling categories

// import start for introducing new
import eveline from '../../../public/images/home/introducing-new/Eveline.png';
import laGirl from '../../../public/images/home/introducing-new/La Girl.png';
import lamel from '../../../public/images/home/introducing-new/Lamel.png';
import makeupRevolution from '../../../public/images/home/introducing-new/Makeup Revolution.png';
// import end for introducing new

const introducingNewData = [
    {
        id: 4,
        src: makeupRevolution,
        displayText: 'Make-up Revolution',
        // redirectURL: 'product-listing?page=1&brand=makeup-revolution-93gd',
        redirectURL: 'product-listing?page=1&brand=makeup-revolution-5omm',
    },
    {
        id: 3,
        src: lamel,
        displayText: 'Lamel',
        // redirectURL: 'product-listing?page=1&brand=lamel-dza8',
        redirectURL: 'product-listing?page=1&brand=lamel-hkkm',
    },
    {
        id: 2,
        src: laGirl,
        displayText: 'L.A Girl',
        // redirectURL: 'product-listing?page=1&brand=la-girl-qpx1',
        redirectURL: 'product-listing?page=1&brand=la-girl-cfj6',
    },
    {
        id: 1,
        src: eveline,
        displayText: 'Eveline',
        // redirectURL: 'product-listing?page=1&brand=eveline-8lvi',
        redirectURL: 'product-listing?page=1&brand=eveline-4ew6',
    },
];

const bestSellingProducts = [
    {
        id: 2,
        src: kama,
        displayText: 'Anti Acne Cleansing Foam',
        redirectURL:
            'product-details?masterSku=944978&variant=6686f20d6b03ccb3efaa67b3',
    },
    {
        id: 4,
        src: maybeline,
        displayText: 'Ls Sky High Wtp Very Black',
        redirectURL:
            'product-details?masterSku=946405&variant=6686cd1d6b03ccb3ef9bd92d',
    },
    {
        id: 5,
        src: superDefence,
        displayText: 'Superdefense City Block Spf 50 Daily Energy',
        redirectURL:
            'product-details?masterSku=948802&variant=6686f9ad6b03ccb3efad5f1c',
    },
    {
        id: 3,
        src: lorealParis,
        displayText: 'Le Matte Resistance French Kiss',
        redirectURL:
            'product-details?masterSku=943546&variant=6686ff666b03ccb3efaf8ddd',
    },
    {
        id: 1,
        src: calvinKlein,
        displayText: 'Calvin Klein One Edt',
        redirectURL:
            'product-details?masterSku=948444&variant=6686ff166b03ccb3efaf6f70',
    },
];

const featuredProductData = [
    {
        id: 1,
        src: anastasia,
        displayText: 'Magic Touch Concealer',
        // redirectURL: 'product-details?masterSku=948219&variant=667649f1b17cb12b26d850f1'
        redirectURL:
            'product-details?masterSku=948219&variant=6686f0b36b03ccb3efa9e114',
    },
    {
        id: 2,
        src: cerave,
        displayText: 'Moisturizing Lotion',
        // redirectURL: 'product-details?masterSku=945706&variant=667646c0b17cb12b26d7a93a'
        redirectURL:
            'product-details?masterSku=945706&variant=6686d9646b03ccb3efa0c65a',
    },
    {
        id: 3,
        src: ferregamo,
        displayText: 'Ferragamo Edt',
        // redirectURL: 'product-details?masterSku=947542&variant=66764977b17cb12b26d83222'
        redirectURL:
            'product-details?masterSku=947542&variant=6686f3746b03ccb3efaaf3f0',
    },
    {
        id: 4,
        src: vitaminsAndNutrition,
        displayText: 'Gnc Glutathione Caps',
        // redirectURL: 'product-details?masterSku=942098&variant=66766b85b17cb12b26dc11a5'
        redirectURL:
            'product-details?masterSku=942098&variant=6686e65c6b03ccb3efa5dcd5',
    },
    {
        id: 5,
        src: cosrx,
        displayText: 'Cosrx Advanced Snail Radiance Dual Essence',
        // redirectURL: 'product-details?masterSku=947625&variant=66764992b17cb12b26d838f8'
        redirectURL:
            'product-details?masterSku=947625&variant=6686f3e26b03ccb3efab1edd',
    },
];

const bestsellingCategoriesData = [
    {
        id: 1,
        src: blush,
        displayText: 'Blush',
        redirectURL: 'product-listing?page=1&first=makeup-and-skin&second=face-yn&third=blush-zu'
    },
    {
        id: 3,
        src: moisturizer,
        displayText: 'Moisturizer',
        redirectURL: 'product-listing?page=1&first=makeup-and-skin&second=skin-care-7m&third=moisturizers-go'
    },
    {
        id: 2,
        src: hairCare,
        displayText: 'Hair Care',
        redirectURL: 'product-listing?page=1&first=wellness-and-personal-care&second=hair-care-4y'
    },
    {
        id: 4,
        src: womenPerfume,
        displayText: 'Women Perfume',
        redirectURL: 'product-listing?page=1&first=fragrance&second=womens-fragrance-7y'
    }
]

export default function Home() {
    const token = getCookie('accessToken');

    const Breakpoints = {
        640: {
            slidesPerView: 4,
        },
    };

    const dispatch = useDispatch<AppDispatch>();
    const [loading, setloading] = useState<boolean>(true);
    const bestSellingProduct = useSelector(
        (state: any) => state.home_store.bestSellingProduct
    );

    const consiousWallProduct = useSelector(
        (state: any) => state.home_store.consiousWallProduct
    );

    const { featuredProduct, introducingProduct } = useSelector(
        (state: any) => state.home_store
    );

    const wishListData = useSelector(
        (state: any) => state.wishlist_store.wishListData
    );
    const wishListDataCount = useSelector(
        (state: any) => state.wishlist_store.wishListDataCount
    );

    useEffect(() => {
        dispatch(getBestSellingProduct({ pageSize: 12, page: 1 })).then(
            (res) => {
                setloading(false);
            }
        );
        dispatch(getFeaturedProduct({ pageSize: 12, page: 1 })).then((res) => {
            setloading(false);
        });

        dispatch(getConsiousWallProduct({ pageSize: 12, page: 1 })).then(
            (res) => {
                setloading(false);
            }
        );
        dispatch(getInroducingProduct({ pageSize: 1, page: 1 })).then((res) => {
            setloading(false);
        });

        if (token) {
            userService.getUserProfile().then((res: any) => {
                dispatch(SetUserId(res._id));
            });
        }

        if (token) {
            dispatch(getWistlistData());
        }
    }, []);

    return (
        <div>
            <DeviceType />
            <IPAddress />
            <MainBanner />
            <FeaturedCategories />

            {/* Best Selling Products slider start */}

            <div className="mx-[3%] @sm:mx-0">
                <div className="border-b py-3 mt-[5%] mb-[2%] ">
                    <div className="flex justify-between items-center ">
                        <h2 className="font-bold text-[#3B3B3B]">
                            Best Selling Products
                        </h2>

                        {/* <Link href={"/"}>
            <div className="text-[#3B3B3B] fs-16">View All </div>
          </Link> */}
                    </div>
                </div>
                {/* {loading ? (
                    <div className="flex justify-between">
                        {[...Array(isMobile ? 2 : 4)].map((_, index) => (
                            <div className="w-[24%] @sm:w-[49%]" key={index}>
                                <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={250}
                                    animation="wave"
                                    className="rounded-md"
                                />
                                <Skeleton width="50%" height={30} />
                                <Skeleton height={30} />
                                <Skeleton width="30%" height={30} />
                                <Skeleton
                                    className="mt-5 rounded-b-md"
                                    variant="rectangular"
                                    height={50}
                                />
                            </div>
                        ))}
                    </div>
                ) : (
                    <CommonSlider
                        initialSlidesPerView={2}
                        breakpoints={Breakpoints}
                        productSlide={bestSellingProduct}
                    />
                )} */}
                <CardList dataToShow={bestSellingProducts} />
            </div>

            {/* Best Selling Products slider End */}

            {/* Full width Banner Start */}
            <div className="mt-[5%] mb-[2%] w-full @sm:w-full ">
                {/* onClick={() => router.push(`/product-listing`)} */}
                {/* <Image
                        className="w-full h-full object-contain @sm:object-cover rounded-xl"
                        width={800}
                        height={800}
                        alt="Full-Width"
                        layout="responsive"
                        src={"/images/home/FeaturedCategories/fullBanner.svg"}
                        /> */}
                <div
                    className="rounded-xl @sm:object-center"
                    style={{
                        backgroundImage:
                            "url('/images/home/discoverAll_banner.webp')",
                        backgroundPosition: 'center top',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        padding: '20px',
                    }}
                >
                    <div className="mt-[22%] @sm:mt-[30%] flex justify-center flex-col items-center">
                        <div className="fs-50 @sm:text-14 @md:text-16 text-white font-bold text-center">
                            DISCOVER ALL PRODUCTS
                        </div>
                        <div className="text-white text-center fs-24 @md-fs-18 leading-tight w-[40%] @sm:w-full my-5 @sm:mt-2 @sm:mb-0">
                            Experience your best self at Aakash Life {`–`} where
                            health, beauty, and community connect uniquely for
                            you.
                        </div>
                    </div>
                </div>
            </div>
            {/* Full width Banner End */}

            {/* Featured Products Slider */}

            <div className="mx-[3%] @sm:mx-0">
                <div className="border-b py-3 mt-[5%] mb-[2%]  ">
                    <div className="flex justify-between items-center ">
                        <h2 className="font-bold text-[#3B3B3B]">
                            Featured Products
                        </h2>
                    </div>
                </div>
                {/* {loading ? (
                    <div className="flex justify-between ">
                        {[...Array(isMobile ? 1 : 3)].map((_, index) => (
                            <div
                                className="h-[30vh] w-[32%] @sm:w-[100%] rounded-md"
                                key={index}
                            >
                                <Skeleton
                                    width="100%"
                                    height="100%"
                                    animation="wave"
                                    variant="rectangular"
                                    className="rounded-md"
                                />
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="w-full h-full">
                        <FeaturedProductSlider
                            productSlide={featuredProduct}
                            type={'featuredProduct'}
                        />
                    </div>
                )} */}
                <CardList dataToShow={featuredProductData} />
            </div>
            {/* Featured Products Slider */}

            {/* Wishlist Slider Start */}

            {token && wishListData.length > 0 && (
                <div className="mx-[3%] @sm:mx-0">
                    <div className="border-b py-3 mt-[5%] mb-[2%] ">
                        <div className="flex justify-between items-center ">
                            <h2 className="font-bold text-[#3B3B3B]">
                                Wishlist
                            </h2>
                            {wishListData.length > 12 && (
                                <Link href={'/wishlist'}>
                                    <div className="text-[#3B3B3B] fs-16">
                                        View All{' '}
                                    </div>
                                </Link>
                            )}
                        </div>
                    </div>

                    <CommonSlider
                        initialSlidesPerView={2}
                        breakpoints={Breakpoints}
                        productSlide={ManipualteWishlistData(
                            wishListData.slice(0, 12)
                        )}
                    />
                </div>
            )}

            {/* Wishlist Slider ENd */}

            {/* Best Selling Categories Slider Start */}

            <div className="mx-[3%] @sm:mx-0 ">
                <div className="border-b py-3 mt-[5%] mb-[2%] ">
                    <div className="flex justify-between items-center ">
                        <h2 className="font-bold text-[#3B3B3B]">
                            Best Selling Categories
                        </h2>
                        {/* <Link href={"/"}>
            <div className="text-[#3B3B3B] fs-16">View All </div>
          </Link> */}
                    </div>
                </div>
                {/* <BestSelling loading={loading} /> */}
                <CardList dataToShow={bestsellingCategoriesData} />
            </div>

            {/* Best Selling Categories Slider ENd */}

            {/* top Brands Slider Start */}

            <div className="mt-[5%] mb-[2%] mx-[3%] @sm:mx-0 ">
                <div className="border-b py-3 mb-5 ">
                    <div className="flex justify-between items-center ">
                        <h2 className="font-bold text-[#3B3B3B]">Top Brands</h2>
                        {/* <Link href={"/"}>
              <div className="text-[#3B3B3B] fs-16">View All </div>
            </Link> */}
                    </div>
                </div>

                <TopBrand />
            </div>

            {/* top Brands Slider ENd */}
            {/* Luxury Categories Slider Start */}

            {/* <div className="bg-[#6E3362CC] rounded-lg w-full mt-[5%] mb-[2%] px-[3.5%] py-[1.5%]">
        <div className="border-b  pb-3 mb-5  ">
          <div className="flex justify-between items-center ">
            <div className="font-bold fs-segoe text-white">Best of Luxury</div>
            <Link href={"/"}>
              <div className="text-white fs-16">View All </div>
            </Link>
          </div>
        </div>
        <div className=" @sm:overflow-x-auto">
          <LuxuryProducts />
        </div>
      </div> */}

            {/* Luxury Categories Slider ENd */}
            {/* Our Blogs  Slider start */}

            {/* <div>
        <div className="border-b py-3 mt-[5%] mb-[2%] ">
          <div className="flex justify-between items-center ">
            <h2 className="font-bold text-[#3B3B3B]">Our Blogs</h2>
          </div>
        </div>
        <div className="w-full @sm:overflow-x-auto @md:overflow-x-auto">
          <BlogCard />
        </div>
      </div> */}
            {/* Our Blogs  Slider ENd */}
            {/* Conscious Wall  Slider start */}

            <div className="bg-[#275B6A] mt-[5%] mb-[2%] rounded-lg  py-[1.5%] mx-0">
                <div
                    className="px-[3.5%] @sm:pt-[3.5%]  w-full @sm:bg-none @md:bg-none"
                    style={{
                        backgroundImage: `url('/images/home/blog/conscious-wall-bgpng.png')`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center 10vh',
                    }}
                >
                    <div className="border-b pb-3 mb-5  @sm:mx-2">
                        <div className="flex justify-between items-center  ">
                            <div
                                style={{
                                    fontFamily: 'Segoe-Script',
                                    fontWeight: 700,
                                }}
                                className="font-bold fs-segoe text-white"
                            >
                                Conscious Wall
                            </div>
                            {/* <Link href={"/"}>
                  <div className="text-white fs-16">View All </div>
                </Link> */}
                        </div>
                    </div>
                    {/* productSlide={consiousWallProduct} */}
                    <ConsciousWall
                        productSlide={consiousWallProduct}
                        loading={loading}
                    />
                </div>
            </div>
            {/* Conscious Wall  Slider end */}

            {/* Introducing New Products Slider */}

            <div className="mx-[3%] @sm:mx-0">
                <div className="border-b py-3 mt-[5%] mb-[2%] ">
                    <div className="flex justify-between items-center ">
                        <h2 className="font-bold text-[#3B3B3B]">
                            Introducing New
                        </h2>
                        {/* <Link href={"/"}>
            <div className="text-[#3B3B3B] fs-16">View All </div>
          </Link> */}
                    </div>
                </div>
                {/* {loading ? (
                    <div className="flex justify-between ">
                        {[...Array(isMobile ? 1 : 3)].map((_, index) => (
                            <div
                                className="h-[30vh] w-[32%] @sm:w-[100%] rounded-md"
                                key={index}
                            >
                                <Skeleton
                                    width="100%"
                                    height="100%"
                                    animation="wave"
                                    variant="rectangular"
                                    className="rounded-md"
                                />
                            </div>
                        ))}
                    </div>
                ) : (
                    <FeaturedProductSlider
                        productSlide={introducingProduct}
                        type={'introducingNewBanner'}
                    />
                )} */}
                <CardList dataToShow={introducingNewData} />
            </div>
            {/* Introducing New Products Slider */}

            {/* Crazy deals  Slider STart */}
            <div className="mx-[3%] @sm:mx-0">
                <div className="border-b py-3 mt-[5%] mb-[2%]">
                    <div className="flex justify-between items-center ">
                        <h2 className="font-bold text-[#3B3B3B]">
                            Crazy Deals
                        </h2>
                    </div>
                </div>

                <div className="w-full">
                    <CrazyDeals />
                </div>
            </div>

            {/* Crazy deals  Slider End */}
            {/* Crazy deals  Slider STart */}

            <div className="mx-[3%] @sm:mx-0">
                <div className="border-b py-3 mt-[5%] mb-[2%]">
                    <div className="flex justify-between items-center ">
                        <h2 className="font-bold text-[#3B3B3B]">
                            Choose excellence with Aakash Life
                        </h2>
                    </div>
                </div>

                <div className="w-full mb-[5%]">
                    <ChooseExcellence />
                </div>
            </div>

            {/* Crazy deals  Slider End */}

            <div className="w-full h-full">
                <FooterBanner />
            </div>
        </div>
    );
}
