'use client';
import Image from 'next/image';
import React, { useState } from 'react';

const excellenceData: ImageHoverProps[] = [
    {
        id: 1,
        normalImage: '/images/home/ChooseExcellence/ChooseExcellence-1.svg',
        hoverImage: '/images/home/ChooseExcellence/ChooseExcellence-blue-1.svg',
        alt: 'Choose excellence with Aakash Life 1',
        heading: 'Authentic<br>Products',
    },
    {
        id: 2,
        normalImage: '/images/home/ChooseExcellence/ChooseExcellence-2.svg',
        hoverImage: '/images/home/ChooseExcellence/ChooseExcellence-blue-2.svg',
        alt: 'Choose excellence with Aakash Life 2',
        heading: 'Omni-<br>Channel',
    },
    {
        id: 3,
        normalImage: '/images/home/ChooseExcellence/ChooseExcellence-3.svg',
        hoverImage: '/images/home/ChooseExcellence/ChooseExcellence-blue-3.svg',
        alt: 'Choose excellence with Aakash Life 3',
        heading: 'Flagship<br>Stores',
    },
    {
        id: 4,
        normalImage: '/images/home/ChooseExcellence/ChooseExcellence-4.svg',
        hoverImage: '/images/home/ChooseExcellence/ChooseExcellence-blue-4.svg',
        alt: 'Choose excellence with Aakash Life 4',
        heading: ' Community- Centric<br>Approach',
    },
    {
        id: 5,
        normalImage: '/images/home/ChooseExcellence/ChooseExcellence-5.svg',
        hoverImage: '/images/home/ChooseExcellence/ChooseExcellence-blue-5.svg',
        alt: 'Choose excellence with Aakash Life 5',
        heading: 'Immersive<br>Experiences',
    },
];

interface ImageHoverProps {
    id: number;
    normalImage: string;
    hoverImage: string;
    alt: string;
    heading: string;
}

const ChooseExcellence = () => {
    const [hoveredId, setHoveredId] = useState<number | null>(null);

    return (
        <div className="flex w-full justify-evenly @sm:justify-start @md:justify-start items-center overflow-x-scroll pb-5">
            {excellenceData.map((item) => (
                <div
                    key={item.id}
                    className="flex-shrink-0 flex flex-col items-center  mr-4"
                >
                    <div
                        //
                        className=" w-[160px] h-[160px] @sm:w-[120px] @md:w-[150px] @sm:h-[100px] @md:h-[100px]  relative"
                        onMouseOver={() => setHoveredId(item.id)}
                        onMouseOut={() => setHoveredId(null)}
                    >
                        <Image
                            className="object-contain w-full h-full"
                            layout="fill"
                            alt={item.alt}
                            src={
                                hoveredId === item.id
                                    ? item.hoverImage
                                    : item.normalImage
                            }
                        />
                    </div>
                    <div className="text-center mt-5">
                        <p
                            className="fs-20 font-semibold leading-tight"
                            dangerouslySetInnerHTML={{
                                __html: item.heading.replace(/\n/g, '<br />'),
                            }}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ChooseExcellence;
