'use client';
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import InputField from '../inputs-fields/input';
import AppleIcon from '@mui/icons-material/Apple';

const FooterBanner = () => {
    const [isMobile, setIsMobile] = useState<undefined | boolean>(undefined);
    useEffect(() => {
        setIsMobile(window.innerWidth <= 768);
    }, []);

    if (typeof isMobile !== 'boolean') return null;
    return (
        <div>
            <div
                className="flex flex-col justify-center items-end w-full @sm:pr-0 rounded-md @sm:mb-[10%] @md:mb-[5%]  @sm:bg-[#F5F5F5] mb-5"
                style={{
                    backgroundImage: `url('${
                        isMobile ? '' : '/images/home/footer-banner.svg'
                    }')`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'bottom',
                }}
            >
                <div className="  @md:hidden @lg:hidden @lgtab:hidden @xl:hidden @2xl:hidden ">
                    <Image
                        className="object-cover aspect-[3/2] @sm:scale-105"
                        width={500}
                        height={500}
                        alt="footer-mob-banner"
                        src={'/images/home/footer-banner-mob.svg'}
                    />
                </div>
                <div className="w-[100%] py-[8%] @md:py-[5%] @md:m-[1%]  @sm:m-0 pr-[10%] @md:pr-[0%] @md:pl-[6%] @sm:px-5 flex flex-col justify-center items-end">
                    <div className="w-[40%] @md:w-[50%] @sm:w-full">
                        <div className="font-bold @md:mt-5  @sm:text-center fs-50 @sm:text-16">
                            Coming Soon
                        </div>
                        <p className="fs-16 @md:text-xs leading-tight my-5  @sm:text-left">
                            Our Aakash Life app is almost here, bringing you
                            easy access to all things beauty, health, wellness,
                            and more. Shopping made simple{`–`} stay tuned,
                            {` it's coming soon!`}
                        </p>

                        {/* <div className="flex mt-[10%] @md:mt-[5%] @sm:mb-[5%] @md:b-[5%]  @sm:gap-2 gap-5 justify-start @sm:justify-between items-center w-full">
              <div className="w-[50%]">
                <Link href={"/"}>
                  <div className="flex bg-[#2D2D32] justify-center items-center p-2 py-2.5 rounded-md gap-2 w-full">
                    <Image
                      className="object-contain filter invert rounded-md  @sm:mr-0 @md:w-5"
                      width={30}
                      height={30}
                      alt="google-play"
                      src={"/images/home/play-store-icon.png"}
                    />
                    <div className="font-semibold fs-24 text-white"> Google Play</div>
                  </div>
                </Link>
              </div>
              <div className="w-[50%]">
                <Link href={"/"}>
                  <div className="flex bg-[#2D2D32] justify-center items-center p-2 py-2.5 rounded-md gap-2 w-full">
                    
                    <AppleIcon className="@md:text-14" sx={{ color: "white", fontSize: 30 }} />
                    <div className="font-semibold fs-24 text-white"> App Store</div>
                  </div>
                </Link>
              </div>
            </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterBanner;
