'use client';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/navigation';
import { AppDispatch } from '@/store/store';
import { getFeaturedData } from '@/store/slices/actions/home-action';
import {
    setClearProductFilter,
    setHeaderFilter,
} from '@/store/slices/filter-slice';
import { ClearProductList } from '@/store/slices/product-slice';

import CardList from './card-list';

import bodyCare from '../../../public/images/home/FeaturedCategories/temp/body-care.jpg';
import face from '../../../public/images/home/FeaturedCategories/temp/face.jpg';
import hairAndCare from '../../../public/images/home/FeaturedCategories/temp/hair-care.jpg';
import lips from '../../../public/images/home/FeaturedCategories/temp/lips.jpg';
import skinCare from '../../../public/images/home/FeaturedCategories/temp/skin-care.jpg';
import sportsNutrition from '../../../public/images/home/FeaturedCategories/temp/sports-nutrition.png';

const dataToShow = [
    {
        id: 2,
        src: face,
        displayText: 'Face',
        redirectURL:
            'product-listing?page=1&first=makeup-and-skin&second=face-yn',
    },
    { id: 6, src: skinCare, displayText: 'Skin Care' , redirectURL: "product-listing?page=1&first=makeup-and-skin&second=skin-care-7m"},
    { id: 4, src: lips, displayText: 'Lips', redirectURL: 'product-listing?page=1&first=makeup-and-skin&second=lips-9t' },
    { id: 1, src: bodyCare, displayText: 'Body Care', redirectURL: 'product-listing?page=1&first=makeup-and-skin&second=skin-care-7m&third=body-care-jn' },
    { id: 3, src: hairAndCare, displayText: 'Hair Care', redirectURL: 'product-listing?page=1&first=wellness-and-personal-care&second=hair-care-4y' },
    { id: 7, src: sportsNutrition, displayText: 'Sports Nutrition', redirectURL: 'product-listing?page=1&first=vitamins-and-nutrition&second=sports-nutrition-ih' },
];

const FeaturedCategories: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const router = useRouter();
    const featured = useSelector((state: any) => state.home_store.featuredData);

    useEffect(() => {
        dispatch(getFeaturedData());
    }, []);

    const handleFeaturedClick = (slug: string) => {
        dispatch(setHeaderFilter(true));
        dispatch(ClearProductList());
        dispatch(setClearProductFilter());
        router.push(`/product-listing?page=1&second=${slug}`);
    };

    return (
        <div className="my-5 px-[3%] @sm:px-0">
            <div className="border-b py-3 mt-[0%] mb-[2%]">
                <h2 className="font-bold text-[#3B3B3B]">
                    Featured Categories
                </h2>
            </div>

            <div className="flex justify-start  items-center flex-wrap @sm:flex-col h-full @sm:overflow-x-auto @md:overflow-x-auto w-full">
                <div className=" w-full flex @md:gap-5 justify-evenly @md:justify-start items-center @sm:gap-4">
                    {/* {featured?.slice(0, 5).map((feature: any) => (
                        <div
                            key={feature?._id}
                            onClick={(e) => {
                                handleFeaturedClick(feature?.slug),
                                    e.stopPropagation();
                            }}
                            className=" border-[3px] border-b-0 cursor-pointer border-[#FF8F2F] hover:border-[#6E3362] px-1 pt-1 @sm:pt-1 @md:pt-1 rounded-t-full w-[19%] @sm:w-[100%] @md:w-[150vw] h-[100%] @sm:mb-3 @md:mb-4 "
                        >
                            <div
                                id="Featured_Categories"
                                className="@md:h-[25vh] @xl:h-[45vh] @lg:h-[50vh]  @sm:h-[25vh] @2xl:[45vh] h-[45vh] w-full object-contain rounded-t-full "
                                style={{
                                    backgroundImage: `url("${feature.image}")`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                }}
                            >
                                <div className="bg-black/20 h-full rounded-t-full">
                                    <div className="text-white flex justify-center items-end h-full w-full @sm:bottom-0">
                                        <div className="border border-white  flex justify-center items-center w-full mx-[10%] @sm:mx-[5%]  rounded-md mb-5 @sm:mb-2">
                                            <div>
                                                <div className="text-white font-bold p-3 @sm:p-2 fs-24">
                                                    {feature?.name?.length > 15
                                                        ? `${feature?.name?.slice(0, 15)}...`
                                                        : feature?.name}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))} */}
                    <CardList dataToShow={dataToShow} />
                </div>
            </div>
        </div>
    );
};

export default FeaturedCategories;
