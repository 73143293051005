import Image from 'next/image';
import React from 'react';
import Link from 'next/link';   
import { Skeleton } from '@mui/material';

import bareNecessities from '../../../public/images/home/conscious-wall/Bare Necessities.png';
import chemistry from '../../../public/images/home/conscious-wall/Juicy Chemistry.jpeg';
import kamaAyurveda from '../../../public/images/home/conscious-wall/Kama Ayurveda.png';
import CommonSlider from '../slider/slider';

interface FeaturedProductSliderProps {
    productSlide: Array<{
        id: number;
        path: string;
        width: number;
        height: number;
        brand?: string | null | undefined; // If brand is optional
        sku: string | undefined | null;
        productName: string;
        price: number | null | undefined;
        cutPrice: number | null | undefined;
        isOutOfStock: boolean;
    }>;
    loading: boolean;
}

const ConsciousWall = ({
    productSlide,
    loading,
}: FeaturedProductSliderProps) => {
    const Breakpoints = {
        640: {
            slidesPerView: 2,
        },
    };

    return (
        <div className="px-[0%] py-[1%] flex justify-center items-center ">
            <div className="flex justify-evenly gap-4 items-start w-full h-full @sm:flex-col">
                <div className="w-[50%] @sm:w-full">
                    <Link
                        href={
                            'product-listing?page=1&brand=kama-ayurveda-vzj7'
                        }
                    >
                        <Image
                            className=" w-full h-[45vh] @md:h-full object-cover rounded-lg @sm:h-full"
                            width={800}
                            height={600}
                            alt="Conscious Wall"
                            // src={'/images/home/blog/ConsciousWall-Img.svg'}
                            src={kamaAyurveda}
                        />
                        <div className="fs-24 font-semibold text-white mt-5">
                            Kama Ayurveda
                        </div>
                        <div className="fs-18 text-white mt-3 leading-normal @sm:hidden">
                            The Ancient Treasure of Ayurveda revisited
                        </div>
                    </Link>
                </div>
                <div className="w-[50%] @sm:w-full">
                    <Link
                        href={
                            'product-listing?page=1&brand=bare-necessities-wqnd'
                        }
                    >
                        <Image
                            className=" w-full h-[45vh] @md:h-full object-cover rounded-lg @sm:h-full"
                            width={800}
                            height={600}
                            alt="Conscious Wall"
                            src={bareNecessities}
                        />
                        <div className="fs-24 font-semibold text-white mt-5">
                            Bare Necessities
                        </div>
                        <div className="fs-18 text-white mt-3 leading-normal @sm:hidden">
                            Making Zero Waste The Norm, And Not The Exception
                        </div>
                    </Link>
                </div>
                <div className="w-[50%] @sm:w-full">
                    <Link
                        href={
                            'product-listing?page=1&brand=juicy-chemistry-36sd'
                        }
                    >
                        <Image
                            className=" w-full h-[45vh] @md:h-full object-cover rounded-lg @sm:h-full"
                            width={800}
                            height={600}
                            alt="Conscious Wall"
                            src={chemistry}
                        />
                        <div className="fs-24 font-semibold text-white mt-5">
                            Juicy Chemistry
                        </div>
                        <div className="fs-18 text-white mt-3 leading-normal @sm:hidden">
                            Certifiably Organic. Certifiably honest
                        </div>
                    </Link>
                </div>
                {/* <div className="w-[50%] h-full @sm:w-full ">
                    {loading ? (
                        <div className="flex justify-between">
                            {[...Array(2)].map((_, index) => (
                                <div className="w-[49%] " key={index}>
                                    <Skeleton
                                        variant="rectangular"
                                        width="100%"
                                        height={250}
                                        animation="wave"
                                        className="rounded-md"
                                    />
                                    <Skeleton width="50%" height={30} />
                                    <Skeleton height={30} />
                                    <Skeleton width="30%" height={30} />
                                    <Skeleton
                                        className="mt-5 rounded-b-md"
                                        variant="rectangular"
                                        height={50}
                                    />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <CommonSlider
                            initialSlidesPerView={2}
                            breakpoints={Breakpoints}
                            productSlide={productSlide}
                        />
                    )}
                </div> */}
            </div>
        </div>
    );
};

export default ConsciousWall;
